import React, { useState } from 'react';
import Modal from 'react-modal';
import { getAuth, createUserWithEmailAndPassword, signInWithEmailAndPassword   } from "firebase/auth";
import firebase from 'firebase/compat/app'; // Ensure this is imported for firestore timestamps
import { analytics} from './firebaseConfig';
import { collection, addDoc, setDoc, doc, getFirestore } from "firebase/firestore"; 

Modal.setAppElement('#root');

const SignUpModal = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [message, setMessage] = useState('');

  const openModal = () => {
    setIsOpen(true);
  };

  const closeModal = () => {
    setIsOpen(false);
    setEmail('');
    setPassword('');
    setError('');
    setMessage('');
  };




  const handleSignUp = async (e) => {
 
    e.preventDefault();
    const auth = getAuth()  
    const db = getFirestore()
    try {
      // Try to sign up the user
    
      const userCredential = await createUserWithEmailAndPassword(auth, email, password);
      setMessage('User signed up successfully!');
      setError(null);
      console.log('User signed up:', userCredential);


      //set the doc

      await setDoc(doc(db, "Users", userCredential.user.uid), {
        email: email,
        credits: 69,
        uid: userCredential.user.uid,
        paid: false
      });
      
   
    
      
    } catch (error) {
      if (error.code === 'auth/email-already-in-use') {
        try {
          // If email is already in use, attempt to sign in the user
          const userCredential = await signInWithEmailAndPassword(auth, email, password);
          setMessage('User signed in successfully!');
          setError(null);
          console.log('User signed in:', userCredential);
        } catch (signInError) {
          setError('Failed to sign in. Please check your password.');
          console.error('Error signing in:', signInError);
        }
      } else {
        // Handle other errors
        setError('Failed to sign up. Please try again.');
        console.error('Error signing up:', error);
      }
    }
  };

 
  return (
    <div>
      {/* Button to open the modal */}
      
      <button style={styles.openModalButton} onClick={openModal}>
        Sign Up/Log In 
      </button>

      {/* Modal view */}
      <Modal
        isOpen={isOpen}
        onRequestClose={closeModal}
        contentLabel="Sign Up Modal"
        style={{
          content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
            width: '400px',
            padding: '30px',
            borderRadius: '10px',
            boxShadow: '0px 0px 15px rgba(0, 0, 0, 0.2)',
            border: 'none',
            overflow: 'visible',
          },
          overlay: {
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
          },
        }}
      >
        <h2 style={styles.modalTitle}>Sign Up</h2>
        <form onSubmit={handleSignUp} style={styles.form}>
          <div style={styles.formGroup}>
            <label style={styles.label}>Email</label>
            <input
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
              style={styles.input}
            />
          </div>
          <div style={styles.formGroup}>
            <label style={styles.label}>Password</label>
            <input
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
              style={styles.input}
            />
          </div>
          <button type="submit">Sign Up</button>
        </form>
        {error && <p style={styles.errorText}>{error}</p>}
        {message && <p style={styles.messageText}>{message}</p>}
        <button style={styles.closeButton} onClick={closeModal}>
          Close
        </button>
      </Modal>
    </div>
  );
};

// Styles for the modal and form elements
const styles = {
  openModalButton: {
    padding: '10px 20px',
    fontSize: '16px',
    fontWeight: 'bold',
    backgroundColor: '#007BFF',
    color: '#fff',
    border: 'none',
    borderRadius: '5px',
    cursor: 'pointer',
    transition: 'background-color 0.3s ease',
    margin: '20px 0',
  },
  modalTitle: {
    marginBottom: '20px',
    fontSize: '24px',
    textAlign: 'center',
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  formGroup: {
    width: '100%',
    marginBottom: '15px',
  },
  label: {
    fontSize: '14px',
    marginBottom: '5px',
    fontWeight: 'bold',
  },
  input: {
    width: '100%',
    padding: '10px',
    fontSize: '16px',
    borderRadius: '5px',
    border: '1px solid #ccc',
    outline: 'none',
    transition: 'border-color 0.3s ease',
  },
  signUpButton: {
    width: '100%',
    padding: '12px',
    fontSize: '16px',
    fontWeight: 'bold',
    backgroundColor: '#28a745',
    color: '#fff',
    border: 'none',
    borderRadius: '5px',
    cursor: 'pointer',
    transition: 'background-color 0.3s ease',
    marginTop: '20px',
  },
  closeButton: {
    marginTop: '20px',
    padding: '10px 20px',
    fontSize: '16px',
    fontWeight: 'bold',
    backgroundColor: '#6c757d',
    color: '#fff',
    border: 'none',
    borderRadius: '5px',
    cursor: 'pointer',
    transition: 'background-color 0.3s ease',
  },
  errorText: {
    color: 'red',
    fontSize: '14px',
    textAlign: 'center',
    marginTop: '10px',
  },
  messageText: {
    color: 'green',
    fontSize: '14px',
    textAlign: 'center',
    marginTop: '10px',
  },
};
export default SignUpModal;
