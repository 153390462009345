import React, { useState } from 'react';
import './PricingModalStyle.css'; // Include a separate CSS file for styling

const PricingModal = ({ isOpen, onClose }) => {


  return (
    <div style={{ justifyContent: 'center',  display: 'flex', alignItems: 'center'}} className="modal-overlay">
      <div className="modal-content">
        {/* Close Button */}
        {/* <button className="close-button" onClick={onClose}>&times;</button> */}

        {/* Modal Header */}
        <h2 className="modal-title">Special Launch Offer</h2>
        <p className="modal-description">To get you barcoding in no time!</p>

        {/* Pricing Options */}
        <div className="pricing-options">
          {/* Monthly Subscription Option */}
          {/* <div className="pricing-card">
            <h3 className="plan-title">Monthly Subscription</h3>
            <p className="plan-price">R299</p>
            <p className="plan-details">Billed monthly, cancel anytime.</p>
            <button className="select-button">Choose Plan</button>
          </div> */}

          {/* Lifetime Access Option */}
          <div className="pricing-card best value">
          <div className="badge">68% Off</div>
<h3 className="plan-title">Lifetime Access</h3>
<p className="plan-price">
  <span className="original-price">R699</span> R222
</p>
<p className="plan-details">One-time payment for unlimited lifetime access.</p>
<button   onClick={() => window.open('https://paystack.com/pay/barcodeboss')}  className="select-button">Buy</button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PricingModal;
