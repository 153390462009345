// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics, logEvent } from "firebase/analytics";
import 'firebase/auth'; 
import { getFirestore } from "firebase/firestore"; 
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyCXwGFRgKHlF8x6rfa8IwqmwuxDQCK4-XQ",
  authDomain: "barcodehelper-30445.firebaseapp.com",
  projectId: "barcodehelper-30445",
  storageBucket: "barcodehelper-30445.appspot.com",
  messagingSenderId: "275514633765",
  appId: "1:275514633765:web:c6c99c99948b677fbcd9cc",
  measurementId: "G-H72CHEDYCT"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const db = getFirestore(app);


export { app, analytics, db };