import React, { useState, useEffect } from 'react';
import Barcode from 'react-barcode';
import { PDFDocument , rgb, StandardFonts} from 'pdf-lib';
import * as pdfjsLib from 'pdfjs-dist/legacy/build/pdf';
import 'pdfjs-dist/legacy/build/pdf.worker.entry';
import JSZip from 'jszip';
import { ThreeDots } from 'react-loader-spinner'; // Import the specific loader type
import './styles.css'; // Import your styles
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
//import { analytics } from "./firebaseConfig"; // Import the analytics object
import { analytics, db } from './firebaseConfig'; // Adjust the path to your firebaseConfig.js
import { getAnalytics, logEvent } from "firebase/analytics";
import { getAuth, signInAnonymously, onAuthStateChanged } from 'firebase/auth';
import logo from './images/bblogo.png';
import diagram from './images/diagram.png';
import Footer from './Footer';
import SignUpModal from './SignUpModal';
import PricingModal from './PricingModal'
import Spinner from './Spinner'; // Adjust the path based on your folder structure
import AnnouncementBar from './AnnouncementBar'; // Adjust the path as needed


import { collection, doc, setDoc, query, getDocs, addDoc, where, getDoc, updateDoc, getFirestore } from "firebase/firestore"; 
import { $uid } from 'pdfjs-dist/legacy/build/pdf.worker';
pdfjsLib.GlobalWorkerOptions.workerSrc = 'pdfjs-dist/legacy/build/pdf.worker.entry.js';

function App() {
  const [barcodes, setBarcodes] = useState([]);
  const [pdfFile, setPdfFile] = useState(null);
  const [loading, setLoading] = useState(false);
  const [spinning, setSpinning] = useState(false);
  const [progress, setProgress] = useState(0); // State to track progress
  const [dailyMessage, setDailyMessage] = useState('');
  const [productNames, setProductNames] = useState([]);
  const [renderedBarcodes, setRenderedBarcodes] = useState([]);
  const BATCH_SIZE = 10;
  const [isExtractionStarted, setIsExtractionStarted] = useState(false);
  const [creditBalance, setCreditBalance] = useState(null);
  const [isPulsating, setIsPulsating] = useState(false);
  const [shippingLabelFile, setShippingLabelFile] = useState(null);
  const [croppedPDF, setCroppedPDF] = useState(null);
  const [user, setUser] = useState('');
  const [userData, setUserData] = useState(null);
  const [paymentStatus, setPaymentStatus] = useState(false)
  const [isModalOpen, setModalOpen] = useState(false);

  const [userID, setUserID] = useState('');
  
  const [isPanelVisible, setIsPanelVisible] = useState(false);



  useEffect(() => {


    const citiesRef = collection(db, "Users");
    const q = query(citiesRef);

     getDocs(q).then(querysnapshot => {
      querysnapshot.forEach((doc) => {
        // doc.data() is never undefined for query doc snapshots
        console.log(doc.id, " => ", doc.data());
      });
      

     })
    
  }, [])




  const handleLogout = () => {
    const auth = getAuth();
    auth.signOut()
      .then(() => {
        console.log("User signed out");
        setUser(null); // Clear user state after logout
      })
      .catch((error) => {
        console.error("Error signing out:", error);
      });
  };
  const togglePanel = () => {
    setIsPanelVisible(!isPanelVisible);
  };

  const batchRenderBarcodes = (startIndex = 0) => {
    const newRenderedBarcodes = barcodes.slice(startIndex, startIndex + BATCH_SIZE);
    setRenderedBarcodes((prevRendered) => [...prevRendered, ...newRenderedBarcodes]);
  
    if (startIndex + BATCH_SIZE < barcodes.length) {
      setTimeout(() => batchRenderBarcodes(startIndex + BATCH_SIZE), 50);
    }
  };

  const handleShippingLabelUpload = (e) => {
    const file = e.target.files[0];
    if (file) {
      setShippingLabelFile(file);
    }
  };
  const processShippingLabel = async () => {
    if (!shippingLabelFile) return;
  
    try {
      const arrayBuffer = await shippingLabelFile.arrayBuffer();
      const pdfDoc = await PDFDocument.load(arrayBuffer);
      
      // Assuming the first page contains the shipping label we want to crop
      const firstPage = pdfDoc.getPage(0);
      const { width, height } = firstPage.getSize(); // PDF page size in points
      
      // 4x6 inches in points
      const cropWidth = 288;  // 4 inches
      const cropHeight = 288; // 6 inches
  
      // Set the crop box to the bottom-left corner of the page
      firstPage.setCropBox(0, 0, cropWidth, cropHeight);
  
      // Serialize the PDFDocument to bytes (ArrayBuffer)
      const pdfBytes = await pdfDoc.save();
      
      // Convert the cropped PDF to a blob and create a URL for download
      const blob = new Blob([pdfBytes], { type: 'application/pdf' });
      const croppedPDFUrl = URL.createObjectURL(blob);
  
      setCroppedPDF(croppedPDFUrl); // Save the cropped PDF URL for download
    } catch (error) {
      console.error("Error processing PDF:", error);
    }
  };
  
  
  

  const handleFileUpload = (event) => {
    const file = event.target.files[0];

    if (file && file.type === 'application/pdf') {

      setPdfFile(null);
      setBarcodes([]);
      setProgress(0);
      setPdfFile(file);
      setBarcodes([]); // Reset barcodes when a new file is uploaded
      setProgress(0);  // Reset progress
    } else {
      alert('Please upload a valid PDF file.');
    }
    console.log()
  };

  
  const extractProductNamesFromPDF = async () => {
    if (!pdfFile) return;
    setLoading(true);
    setIsExtractionStarted(true);
    const pdf = await pdfjsLib.getDocument(URL.createObjectURL(pdfFile)).promise;
    const productNames = [];
    let currentProductName = '';
  
    for (let pageNum = 1; pageNum <= pdf.numPages; pageNum++) {
      const page = await pdf.getPage(pageNum);
      const textContent = await page.getTextContent();
  
      textContent.items.forEach((item) => {
        const textItem = item.str.trim();
        const isPotentialProductName = isNaN(textItem) && textItem.length > 0;
  
        if (isPotentialProductName) {
          currentProductName += (currentProductName ? ' ' : '') + textItem;
        } else {
          if (currentProductName.length > 0) {
            productNames.push(sanitizeProductName(currentProductName));
            currentProductName = ''; 
          }
        }
      });
  
      if (currentProductName.length > 0) {
        productNames.push(sanitizeProductName(currentProductName));
        currentProductName = ''; 
      }
  
      setProgress(((pageNum / pdf.numPages) * 100).toFixed(0));
    }
  
    setProductNames(productNames); // Store product names in state without removing duplicates
  
    setLoading(false);
    setProgress(0); 
    console.log(productNames.length)
  };
  
  // Helper function to sanitize product names
 
  // Helper function to sanitize product names

  const extractBarcodesFromPDF = async () => {
    if (!pdfFile) return;
    setLoading(true);
  
    const pdf = await pdfjsLib.getDocument(URL.createObjectURL(pdfFile)).promise;
    const extractedBarcodes = [];
  
    for (let pageNum = 1; pageNum <= pdf.numPages; pageNum++) {
      const page = await pdf.getPage(pageNum);
      const textContent = await page.getTextContent();
  
      let combinedText = '';
  
      textContent.items.forEach((item) => {
        combinedText += item.str.trim().replace(/[\s\-\&\/\,]+/g, ''); // Remove spaces, dashes, ampersands, slashes, and commas
      });
  
      // Extract sequences of exactly 13 digits
      const potentialBarcodes = combinedText.match(/(?:\d{13}(?!\d))/g); // Ensure it's exactly 13 digits
  
      if (potentialBarcodes) {
        potentialBarcodes.forEach((barcode) => {
          extractedBarcodes.push(barcode); // Add each barcode, including duplicates
        });
      }
  
      setProgress(((pageNum / pdf.numPages) * 100).toFixed(0));
    }
  
    const totalBarcodes = extractedBarcodes.length;
    const uniqueBarcodes = [...new Set(extractedBarcodes)]; // Find unique barcodes
    const uniqueCount = uniqueBarcodes.length;
    const duplicateCount = totalBarcodes - uniqueCount;
  
    console.log(`Extracted barcodes: ${extractedBarcodes}`);
    console.log(`Total number of barcodes: ${totalBarcodes}`);
    console.log(`Number of unique barcodes: ${uniqueCount}`);
    console.log(`Number of duplicate barcodes: ${duplicateCount}`);

    const analytics = getAnalytics();

// Function to handle barcode extraction

  logEvent(analytics, 'extract_barcodes', {
    quantity: totalBarcodes,
  });
  
    setBarcodes(extractedBarcodes); // Store all barcodes, including duplicates
    setLoading(false);
    setProgress(0); // Reset progress after extraction
    extractProductNamesFromPDF()
  };
  
  const sanitizeProductName = (name) => {
    return name.replace(/^P M /, '').replace(/[^a-zA-Z0-9\s]/g, ''); // Remove "P M " and special characters, keep letters, numbers, and spaces
  };  

  const generatePDF = async () => {
    if (barcodes.length === 0) return;

    if (!user) {

      alert("Please sign up or log in to download your barcodes!");
return
    }
    else if (paymentStatus == false){
      alert("Please buy access to download your barcodes");
      return
    }
else {
  
    setLoading(true);
  
    const pdfDoc = await PDFDocument.create();
    const pdfWidth = 1000;
    const pdfHeight = 750;
  
    const drawTextWithWrapping = (page, text, options) => {
      const { x, y, font, size, color, maxWidth } = options;
      const words = text.split(' ');
      let line = '';
      let currentY = y;
  
      words.forEach((word) => {
        const testLine = line + word + ' ';
        const testWidth = font.widthOfTextAtSize(testLine.trim(), size);
  
        if (testWidth > maxWidth) {
          page.drawText(line.trim(), {
            x: x,
            y: currentY,
            size: size,
            font: font,
            color: color,
          });
          line = word + ' ';
          currentY -= size + 4;
        } else {
          line = testLine;
        }
      });
  
      if (line.length > 0) {
        page.drawText(line.trim(), {
          x: x,
          y: currentY,
          size: size,
          font: font,
          color: color,
        });
      }
    };
  
    const font = await pdfDoc.embedFont(StandardFonts.Helvetica); // Use Helvetica for both text and barcode numbers
    const boldFont = await pdfDoc.embedFont(StandardFonts.HelveticaBold); // Use Helvetica Bold for product names
  
    for (let i = 0; i < barcodes.length; i++) {
      const page = pdfDoc.addPage([pdfWidth, pdfHeight]);
  
      const productName = productNames[i] || "Unknown Product";
      const sanitizedProductName = productName.replace(/[\/\\?%*:|"<>]/g, '-');
  
      const fontSize = 60;
      const maxWidth = pdfWidth - 100;
  
      drawTextWithWrapping(page, productName, {
        x: 50,
        y: pdfHeight - 130,
        size: fontSize,
        font: font,
        color: rgb(0, 0, 0),
        maxWidth: maxWidth,
      });
  
      const svgElement = document.getElementById(`barcode-${i}`);
      const svgData = new XMLSerializer().serializeToString(svgElement);
      const svgBlob = new Blob([svgData], { type: 'image/svg+xml;charset=utf-8' });
      const url = URL.createObjectURL(svgBlob);
  
      const img = new Image();
      img.src = url;
  
      await new Promise((resolve) => {
        img.onload = resolve;
      });
  
      const canvas = document.createElement('canvas');
      const context = canvas.getContext('2d');
  
      const naturalWidth = img.width;
      const naturalHeight = img.height;
      const aspectRatio = naturalWidth / naturalHeight;
  
      const imgWidth = Math.min(900, pdfWidth * 0.9);  // Adjust the multiplier for larger size
      const imgHeight = imgWidth / aspectRatio;
  
      canvas.width = imgWidth;
      canvas.height = imgHeight;
      context.drawImage(img, 0, 0, canvas.width, canvas.height);
  
      const pngDataUrl = canvas.toDataURL('image/png');
      const pngImage = await pdfDoc.embedPng(pngDataUrl);
      const fontTest = await pdfDoc.embedFont(StandardFonts.Helvetica);
      page.drawImage(pngImage, {
        x: (pdfWidth - imgWidth) / 2 - 40,
        y: (pdfHeight - imgHeight) / 2 - 80, // Adjust position to center below the text
        width: imgWidth,
        font: fontTest,
        
        height: imgHeight,
      });
  
      const mpFontSize = 60;
      const mpText = 'M\nP'; // Vertically arranged "M P"
      page.drawText(mpText, {
        x: 50, // Position closer to the left side
        y: pdfHeight - 370, // Adjust the Y position as needed
        size: mpFontSize,
        font: font,
        color: rgb(0, 0, 0),
        lineHeight: mpFontSize + 10, // Adjust line height for spacing
      });
  
      setProgress(((i + 1) / barcodes.length * 100).toFixed(0));
    }
  
    const date = new Date();
    const formattedDate = date.toISOString().split('T')[0]; 
  
    const pdfBytes = await pdfDoc.save();
    const blob = new Blob([pdfBytes], { type: 'application/pdf' });
    const url = URL.createObjectURL(blob);
  
    const a = document.createElement("a");
    a.href = url;
    a.download = `barcodes_${formattedDate}.pdf`;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  
    setProgress(0);
    setLoading(false);
    handleDownloadComplete();
  
    const analytics = getAnalytics();
    logEvent(analytics, 'download_barcodes', {
      quantity: productNames.length,
    });
   } };
  const handleDownloadComplete = async () => {

    const db = getFirestore()
    // Show the toast notification
    toast.success('Barcodes Downloaded!');
   // setCreditBalance(creditBalance - barcodes.length); 
    console.log(creditBalance)
    
   
 ///  console.log("hIII")
  // updateUserCredits(creditBalance - barcodes.length)
    



    setTimeout(() => {
      window.location.reload();
    }, 5000); // 2-second delay before refreshing
  };

  const updateUserCredits = async (newBalance) => {


  console.log("heleow")
    
    const washingtonRef = doc(db, "Users", userID);

    await updateDoc(washingtonRef, {
      credits: newBalance
    });
    console.log('Document successfully updated!');
    getUserDetails(userID)





  };


  const getCreditColor = () => {
    if (creditBalance < 250) {
      return 'red';
    } else if (creditBalance < 500) {
      return '#fcc203';
    } else {
      return 'green';
    }
  };
  const auth = getAuth();

 const getUserDetails = async (uid) => {
  setSpinning(true)
  const currentUser = user


  const docRef = doc(db, "Users", uid);
  const docSnap = await getDoc(docRef);
  const data = docSnap.data()
  if (docSnap.exists()) {
    setUserData(data)
    setPaymentStatus(data.paid)
    setCreditBalance(data.credits)
    console.log("Document data:", docSnap.data());
    setSpinning(false)
  } else {
    // docSnap.data() will be undefined in this case
    console.log("No such document!");
    setSpinning(false)
  }
  

}



useEffect(() => {



  onAuthStateChanged(auth, (user)  => {
  
    if (user) {
   
      // User is signed in, see docs for a list of available properties
      // https://firebase.google.com/docs/reference/js/auth.user
      const uid = user.uid;
   
      console.log(uid)
      setUser(user)
    
      getUserDetails(uid)
      console.log("user data +" + userData)
      setUserID(uid)
   
      // ...
    } else {
      // User is signed out
      setUserID('')
      // ...
    }
  });
 
  if (barcodes.length > 0) {
    batchRenderBarcodes();
  }
}, [barcodes]);
  
const handleButtonClick = () => {
  const analytics = getAnalytics();
  logEvent(analytics, 'Javahn Is amazing', {
    content_type: 'image',
    content_id: 'P12453'
  });
};
  return (
    <div className="app-wrapper">
      {/* Main Content */}
  
      <div className="main-content">
    
        <header className="header-section">
        <div className="logo-container">
        <img src={logo}  alt="Barcode Boss Logo" className="logo" />
  </div>
          
          <p className="app-description">
          Barcode Boss converts your A4 barcode PDF from Takealot into individual barcodes for easy thermal printing. Just upload, extract, and download!
          </p>
          <div className="diagram-container">
        <img src={diagram}  alt="How Barcode Boss works" className="diagram" />
       


  </div>
          
        
          <p style={{ fontWeight: 'bold' }}>
            <br />
            Feedback & improvements: barcodeboss@trophythermal.co.za
          </p>
       
        </header>


    {/*    <div>
      <h2>Upload Shipping Label</h2>
      <input type="file" accept="application/pdf" onChange={handleShippingLabelUpload} />
      <button onClick={processShippingLabel} disabled={!shippingLabelFile}>
        Process Shipping Label
      </button>

      {croppedPDF && (
        <a href={croppedPDF} download="cropped-shipping-label.pdf">
          Download Cropped Shipping Label
        </a>
      )}
    </div>
      */}

    <div className="container">
          <h2>Upload a PDF To Begin</h2>
          <input type="file" accept="application/pdf" onChange={handleFileUpload} />
          <div>
            <button onClick={extractBarcodesFromPDF} disabled={!pdfFile || isExtractionStarted}>
              Extract Barcodes
            </button>
            <ToastContainer position="top-center" autoClose={1500} />
      </div> 


          {barcodes.length > 0 && (
            <>
              <h3>Total Barcodes Extracted: {barcodes.length}</h3>

              <div className="barcode-grid-card">
                <div className="barcode-container">
                  {renderedBarcodes.map((barcode, index) => (
                    <div key={index} className="barcode">
                      <div className="product-name">{productNames[index] || 'Unknown Product'}</div>
                      <Barcode value={barcode} font="sans-serif" format="EAN13" height={50} id={`barcode-${index}`} />
                    </div>
                  ))}
                </div>
              </div>
            </>
          )}

          {loading && (
            <div className="progress-container">
              <progress value={progress} max="100" className="progress-bar" />
              <p>{progress}% Complete</p>
            </div>
          )}

          {barcodes.length > 0 && (
            <button className="generate-button" onClick={generatePDF}>
              Download Barcodes!
            </button>
          )}
        </div>
      </div>
    
     
      {/* Side Panel */}
      <aside className={`side-panel ${isPanelVisible ? 'visible' : ''}`}>
        {/* <h2 style={{ color: getCreditColor() }}>{creditBalance} Credits</h2>
        <p style={{textAlign:"center"}}>1 Credit = 1 Barcode</p> */}
       
       {spinning ?  <Spinner /> : user && paymentStatus == true ?<div style={{
  display: 'flex',
  alignItems: 'center',
  padding: '10px 20px',
  backgroundColor: '#e0ffe0',
  border: '1px solid #4caf50',
  borderRadius: '5px',
  margin: '10px 0',
  fontSize: '1rem',
  color: '#333'
}}>
  {/* Badge Icon */}
  <span style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          backgroundColor: '#4caf50',
          color: '#fff',
          borderRadius: '50%',
          width: '20px', // Adjusted for better visibility
          height: '20px', // Adjusted for better visibility
          marginRight: '10px',
          fontWeight: 'bold',
          lineHeight: '1' // Ensures the icon is centered
        }}>
          ✓
        </span>
  {/* Lifetime Access Message */}
  <span>You have purchased lifetime access to Barcode Boss</span>
</div> : !user ? <div /> :  <PricingModal style={{ justifyContent: 'center',  display: 'flex', alignItems: 'center'}}isOpen={isModalOpen} onClose={() => setModalOpen(false)} /> }
{/* <button
         onClick={() => setModalOpen(true)}
          style={{
            backgroundColor: '#007bff',
            color: '#fff',
            padding: '10px 20px',
            border: 'none',
            borderRadius: '5px',
            fontSize: '1rem',
            cursor: 'pointer',
            marginTop: '10px'
          }}
        >
          Get Lifetime Access for just R248!
        </button>   */}


 
    

        
       
       
      
        <div className="user-info" style={{
  display: 'flex',
  flexDirection: 'column', // Stacks items vertically
  alignItems: 'center', // Centers items horizontally
  padding: '20px', // Adds padding around the content
  textAlign: 'center' // Centers text within the div
}}>
  {/* User Icon */}
  {!user ? (
    <div className="user-icon" style={{
      marginBottom: '10px' // Adds space below the icon
    }}>
      <svg width="48" height="48" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx="12" cy="8" r="4" stroke="#333" strokeWidth="2"/>
        <path d="M4 22C4 18.6863 7.58172 16 12 16C16.4183 16 20 18.6863 20 22" stroke="#333" strokeWidth="2"/>
      </svg>
    </div>
  ) : null}
  
  {/* Sign Up Modal */}
  {!user ? <SignUpModal /> : null}
  
  {/* User Email */}
  <div className="user-info-text" style={{
    marginBottom: '10px', // Adds space below the email
  }}>
    <p>{user ? user.email : null}</p>
  </div>

  {/* Log Out Button */}
  {user ? (
    <button className="logout-button" onClick={() => handleLogout()} style={{
      padding: '10px 20px', // Adds padding to the button
     // backgroundColor: '#007bff', // Button background color
      color: '#5c5c5c', // Button text color
      textAlign:'center',
      border: 'none',
      borderRadius: '5px',
      
      cursor: 'pointer',
      marginTop: '10px', // Adds space above the button
    }}>
      Log Out
    </button>
  ) : null}

  {/* Link Section at the Bottom */}
  <div style={{
    marginTop: 'auto', // Pushes the link to the bottom of the side panel
    padding: '20px 0',
    textAlign: 'center',
  }}>
    {/* Place your link here */}
    <a href="https://www.freeprivacypolicy.com/live/7cd9d95f-2902-4e30-9397-b9899f8ac550" target="_blank" style={{
      color: '#007bff', // Link color
      textDecoration: 'none',
    }}>
      Privacy Policy
    </a>
  </div>
</div>

      </aside>

      {/* Burger Menu button for mobile */}
      <div className="burger-menu" onClick={togglePanel}>
        <div className={`bar ${isPanelVisible ? 'open' : ''}`}></div>
        <div className={`bar ${isPanelVisible ? 'open' : ''}`}></div>
        <div className={`bar ${isPanelVisible ? 'open' : ''}`}></div>
      </div>

      {/* Style for mobile responsiveness */}
      <style jsx>{`
        .app-wrapper {
          display: flex;
          justify-content: center;
          align-items: flex-start;
          position: relative;
       
        }

        .main-content {
          max-width: 1200px;
          width: 100%;
          margin: 0 auto;
          padding: 20px;
          margin-Top: '100px';
        }

        .side-panel {
          width: 200px;
          background-color: #f4f4f4;
          padding: 20px;
          position: fixed;
          top: 0;
          right: 0;
          height: 100vh;
          border-left: 1px solid #ddd;
          transition: transform 0.3s ease;
          transform: translateX(0); /* Default position for larger screens */
        }

        .side-panel.visible {
          transform: translateX(0);
        }

        .user-info {
          margin-top: 20px;
          font-size: 0.9rem;
          border-top: 1px solid #ddd;
          padding-top: 10px;
        }
    
        .user-info-content {
        
          flex-direction: column;
          align-items: flex-start;
        }
    
        .user-info-name,
        .user-info-email {
          margin: 5px 0;
          color: #333;
        }
        .diagram-container{
          display: flex;
          margin: 0 auto; 
          align-items: center;
          justify-content: center;
     max-width: 450px;
  
        }
        .diagram{

          width: 350px;
          align-items: center;
          justify-content: center;
        }
    
        .logout-button {
          
          border: none;
          background: none;
          font-size: 0.9rem;
          cursor: pointer;
          margin-top: 10px;
          text-align: left;
          padding: 0;
        }
    
    
        .logout-button:hover {
          text-decoration: underline;
          background: #fff;
        }
        .side-panel .user-info {
          margin-top: 20px;
          display: flex;
          align-items: center;
          justify-content: space-between;
        }
        
        .side-panel .user-info .user-icon {
          width: 40px;
          height: 40px;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 50%;
          background-color: #e0e0e0;
        }
        
        .side-panel .user-info .user-icon svg {
          fill: #333;
        }
        
        .side-panel .user-info .user-info-text {
          flex: 1;
          margin-left: 10px;
        }
        
        .side-panel .user-info .user-info-text p {
          margin: 0;
          color: #333;
        }
        .logo {
          max-width: 100%; /* Ensures the logo can grow based on the container size */
          width: 200px; /* Adjust the size */
        }
        .side-panel .logout-button {
        
          border: none;
          background: #fff
          cursor: pointer;
          font-size: 0.875rem;
          padding: 0;
          margin-top: 10px; /* Add margin to separate from user info */
          text-align: left;
          width: 100%; /* Make the button full width */
        }
        .side-panel  .logout-button:hover{

          background: none;

        }
        .burger-menu {
          display: none;
          position: fixed;
          top: 15px;
          right: 15px;
          width: 30px;
          height: 25px;
          justify-content: space-between;
          flex-direction: column;
          cursor: pointer;
        }

        .bar {
          width: 30px;
          height: 4px;
          background-color: #333;
          transition: all 0.3s ease;
        }

        .bar.open:nth-child(1) {
          transform: rotate(45deg) translate(5px, 5px);
        }

        .bar.open:nth-child(2) {
          opacity: 0;
        }

        .bar.open:nth-child(3) {
          transform: rotate(-45deg) translate(5px, -5px);
        }

        @media (max-width: 768px) {
          .side-panel {
            transform: translateX(100%); /* Hidden off-screen for mobile */
          }

          .side-panel.visible {
            transform: translateX(0); /* Slide in when visible on mobile */
          }

          .burger-menu {
            display: flex; /* Show the burger menu only on mobile */
          }

          .main-content {
            margin: 0 20px;
            padding: 10px;
          }
        }
      `}</style>
   
    </div>
  );
  
  
  
  
  
}

export default App;
